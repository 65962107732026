/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
//@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/styles/mat-tab.scss";
@import "./assets/styles/mat_form.scss";
@import "./assets/styles/main.scss";
html, body {
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

body {
  margin: 0;
}


html {
    .popular-plan{
        transform: scale(1.1);
        z-index: 1;
    }
    .price-listing{
    margin-bottom: 30px;
        .mat-list-item{
            height: auto;
            .mat-list-item-content{
                display: block;
                padding: 20px 0;
            }
        }
    }
}

mat-sidenav-container, mat-sidenav-content, mat-sidenav {
  height: 100%;
}
mat-toolbar{
  background-color: #5c6787
}
mat-sidenav {
  width: 250px;
}

a {
  text-decoration: none;
  color: white;
}

a:hover,
a:active {
  color: lightgray;
}

.navigation-items {
  list-style: none;
  padding: 5px;
  margin: 0;
}

.mat-button, .mat-fab, .mat-icon-button, .mat-mini-fab, .mat-raised-button {
  color: white;
  background-color: #5c6787;
}

.example-full-width {
  width: 100%;
}

#sectionLogin{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#sectionCenter{
  display: flex;
  width: 100%;
  height: calc(100% - 118px);
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#sectionAbout{
  display: flex;
  width: 100%;
  height: 85%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sectionPadrao{
  display: flex;
  width: 100%;
  min-height: calc(100% - 175px);
}

.cardFullEmail{
  min-height: calc(100% - 175px) !important;
}

.sectionPadraoFull{
  display: flex;
  width: 100%;
  min-height: calc(100% - 44px);
}

.divContainer{
  display: flex;
  flex-direction: row
}

#formNome{
  width: 500px !important;
}

#formNascimento{
  width: 160px !important;
  max-width: 97%;
}

#formSexo{
  margin-top: -4px;
  width: 100px !important;
  max-width: 97%;
}

#formTelefone{
  width: 150px !important;
  max-width: 97%;
}

#formCPF{
  width: 150px !important;
  max-width: 97%;
}

#formCEP{
  width: 120px !important;
  max-width: 97%;
}

#form97{
  width: 97% !important;
}

#formFull{
  width: 100% !important;
}

.matHidden{
  display: none !important;
}

#txtFiltro{
  width: 300px !important;
}

a, .linkPointer{
	cursor: pointer;
}

.defaultPointer{
  cursor: default;
}

.gridRed{
  color: red !important;
  font-weight: 600
}
.colorRed{
  color: red !important;
}
.fontBold{
  font-weight: bold; 
}
.mat-dialog-container{
  padding: 0px !important;
}
.cardGeral{
  margin:6px 6px 6px 6px; 
  width: 100%;
}
.calendar{
  width: 310px;
  height: 350px;
}