.mat-drawer-container {
    background-color: #f1f1f1 !important;
}
.mat-card{
    background:#fff;
}
.mat-card-topo{
    border-radius: 0px !important;
}
.mat-drawer, .mat-drawer-container, .mat-expansion-panel-header, #menuLetters {
    font-size: 14px !important;
    font-family: Roboto,"Helvetica Neue",sans-serif !important;
    background-color: white !important;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.mat-list-item, .mat-panel-title, .mat-expansion-panel-header, #menuLetters{
    color:#8f999e !important;
    font-weight: bold !important;
}

#snav .mat-nav-list .mat-list-item .mat-list-item-content:hover>a {
    color: #1e88e5 !important;
}

.mat-expansion-panel:not([class*=mat-elevation-z]),.mat-drawer, .mat-drawer-container, .mat-expansion-panel-header, #menuLetters {
    box-shadow: none !important;
}

.mat-expansion-panel-header {
    padding: 0 10px 0 0 !important;
}
.mat-expansion-panel-body {
    padding: 0px !important
}
.submenu{
    padding-left: 27px !important;
}

.buttonSuccess{
    background-color: green !important
}

.comboPadrao{
    color: #000 !important;
}

// .mat-select-value {
//     color: #fff !important;
// }

// .mat-select-arrow {
//     color: #fff !important;
// }

.mat-form-field{
    line-height: 1.4 !important;
}