.mat-header-row, .mat-paginator-container{
    height: 40px; 
    min-height: 30px !important;
    background-color: #5c6787 !important;
}
.mat-header-row{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.mat-paginator-range-label, .mat-paginator-page-size-label, .mat-header-cell{
    color:white !important;
    font-size: 14px !important;
    font-weight: bold !important;    
}
.mat-cell{
    font-size: 14px;
    color:black !important
}
.mat-footer-row, .mat-header-row, .mat-row{
    border-style: none !important;
}
.mat-row{
    height: 35px; 
    min-height: 30px !important;
}
.mat-row:nth-child(even){
    background-color:#dee2e6;
}
.mat-row:nth-child(odd){
    background-color:white;
}
.mat-grid-tile-footer,.mat-grid-tile-header{
    font-size:14px
}
.mat-grid-tile-footer .mat-line,.mat-grid-tile-header .mat-line{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  display:block;
  box-sizing:border-box
}.mat-grid-tile-footer .mat-line:nth-child(n+2),.mat-grid-tile-header .mat-line:nth-child(n+2){
  font-size:12px
}
.mat-select-value{
    font-size: 14px !important;
    font-weight: bold !important;   
}
.mat-paginator, .mat-table {
    background: none !important;
}
.mat-paginator-container{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 15px 8px 0px !important;
}
.mat-paginator-page-size-label {
    margin-top:20px !important;
}
mat-cell:first-of-type, mat-footer-cell:first-of-type, mat-header-cell:first-of-type {
    padding-left: 5px !important;
}
mat-cell:last-of-type, mat-footer-cell:last-of-type, mat-header-cell:last-of-type {
    padding-right: 5px !important;
}
.mat-icon-button, .mat-mini-fab {
    //background-color: transparent !important
    padding: 4px !important;
}
.mat-paginator-outer-container{}